@import 'npm:@splidejs/splide/dist/css/splide-core.min.css';

::selection {
  background: #3d2423;
  color: #fff3f3;
}

body {
  -webkit-font-smoothing: antialiased;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
}

.field {
  border-radius: 8px 0px 0px 8px;
}

.submit-button {
  border-radius: 0px 8px 8px 0px;
}

.w-input,
.w-select {
  border: none;
}

.show-child {
  height: auto;
  max-height: 300px;
}

.show-child p.text-small.feature-card-body {
  opacity: 1;
}

.feature-card {
  scroll-margin: 25px;
}

@media screen and (min-width: 1500px) {
  .hero-image-map {
    right: calc(50% - 810px);
  }

  .hero-image-shape {
    right: calc(50% - 215px);
  }

  .hero-image-white {
    right: calc(50% - 720px);
  }

  .history-texture {
    left: calc(50% - 900px);
  }

  .cadby-hall {
    top: clamp(178px, 18vw, 265px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1120px) {
  .pencil-banner-text {
    font-size: 3.6vw;
  }
}

@media screen and (max-width: 550px) {
  .nav-link {
    display: none;
  }
}